$(document).ready(function() {

	// Mobile Menu
	$('.btn-nav').on( "click", function() {
		$('ul.nav').toggleClass('opened');
	});

	// Active State to Menu
	$(".nav-item a").filter(function(){
		return this.href == location.href.replace(/#.*/, "");
	}).addClass("active");

	// Bootstrap Navbar clickable links on top level
	$('.dropdown-toggle').click(function() {
		var location = $(this).attr('href');
		window.location.href = location;
		return false;
	});

	// Slider
	$("#slider").owlCarousel({
		navigation: false,
		slideSpeed: 300,
		paginationSpeed: 400,
		singleItem: true,
		autoPlay: 4000,
		transitionStyle: 'fade'
	});

	// Carousel Partners
	$("#partners").owlCarousel({
	  autoPlay: 3000,
	  items : 8,
	  itemsScaleUp: false,
	  itemsDesktop : [1199,6],
	  itemsDesktopSmall : [990,5],
	  itemsTablet : [766,4],
	  itemsMobile : [542,2],

	});

	// Detect mailto for ga events
    $('a[href^="mailto:"]').on( 'click', function() {
        ga('send', 'event', 'Click Email', 'Email di testo');
    });
    $('a[href^="tel:"]').on( 'click', function() {
        ga('send', 'event', 'Click Telefono', 'Telefono di testo');
    });

	if (window.location.href== "http://www.prontointerventoexpress.it/confirm") {
		setTimeout(window.location.href = 'http://www.prontointerventoexpress.it', 4000);
	}

});
